import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { KurzUnitService } from '../../services/kurz-unit.service';

interface OldArgs {
  value: number;
  kurzUnit: string;
  unitFactor: number;
  explicitCurrencyIso: string;
  separator: string;
}

@Pipe({
  name: 'kurzPricePerUnit',
  pure: false
})
export class KurzPricePerUnitPipe implements PipeTransform, OnDestroy {

  private oldArgs: OldArgs;

  private sub: Subscription;

  private currentPPU = '';

  constructor(private kurzUnitService: KurzUnitService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * translates the kurz specific unit strings (M2, ROL, PFI, MSI, MMM, FTI' note: case insensitive in algorithm)
   * @param unit - string, which needs to be piped
   * @param unitFactor - number, first argument of the pipe and the unit factor of the translation, default: 1
   * @param maximumFractionDigits - number, second argument of the pipe and maximum allowed fraction digits of the number, default: 2
   * @param minimumFractionDigits - number, third argument of the pipe and minimum allowed fraction digits of the number, default: 0
   * @returns string
   */
  transform(value: number, kurzUnit: string, unitFactor: number, explicitCurrencyIso?: string, separator: string = ' / '): string {
    if (this.isNew(value, kurzUnit, unitFactor, explicitCurrencyIso, separator)) {
      this.sub?.unsubscribe();
      this.sub = this.kurzUnitService.getPricePerUnit(value, kurzUnit, unitFactor, explicitCurrencyIso, separator).subscribe(ppu => {
        this.currentPPU = ppu;
        this.cdr.markForCheck();
      });
    }
    return this.currentPPU;
  }

  /**
   * are there new args
   */
  private isNew(value: number, kurzUnit: string, unitFactor: number, explicitCurrencyIso: string, separator: string): boolean {

    const isNew = !this.oldArgs ||
                  value !== this.oldArgs.value ||
                  unitFactor !== this.oldArgs.unitFactor ||
                  kurzUnit !== this.oldArgs.kurzUnit ||
                  explicitCurrencyIso !== this.oldArgs.explicitCurrencyIso ||
                  separator !== this.oldArgs.separator
                  ;

    if (isNew) {
      this.oldArgs = {value, unitFactor, kurzUnit, explicitCurrencyIso, separator};
    }

    return isNew;

  }

}
